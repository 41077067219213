const BASE_URL = 'https://api.overseas.optongroup.ru/api';

export const userService = {
  async getUserData() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const response = await fetch(`${BASE_URL}/auth/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to fetch user data');
    }

    return response.json();
  },

  async getFavorites() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const response = await fetch(`${BASE_URL}/apartments/user/favorites`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      if (response.status === 422) {
        return [];
      }
      throw new Error('Failed to fetch favorites');
    }

    return response.json();
  },

  async getRequests() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    try {
      const response = await fetch(`${BASE_URL}/requests/user`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        if (response.status === 404) {
          return [];
        }
        throw new Error('Failed to fetch requests');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching requests:', error);
      return [];
    }
  },

  async getRealtorInfo() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
      throw new Error('User info not found');
    }

    try {
      const response = await fetch(`${BASE_URL}/realtors/${user.id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        if (response.status === 404) {
          return null;
        }
        throw new Error('Failed to fetch realtor info');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching realtor info:', error);
      return null;
    }
  },

  async createApartment(data) {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    const user = JSON.parse(localStorage.getItem('user'));
    if (!user || !user.id) {
      throw new Error('User info not found');
    }

    try {
      const response = await fetch(`${BASE_URL}/apartments/`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ...data,
          city_id: parseInt(data.city_id),
          price: parseFloat(data.price),
          realtor_id: user.id
        })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.detail || 'Failed to create apartment');
      }

      return response.json();
    } catch (error) {
      console.error('Error creating apartment:', error);
      throw error;
    }
  },

  async getApartmentTypes() {
    try {
      const response = await fetch(`${BASE_URL}/apartments/types`);
      if (!response.ok) {
        throw new Error('Failed to fetch apartment types');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching apartment types:', error);
      return [];
    }
  },

  async getCities() {
    try {
      const response = await fetch(`${BASE_URL}/locations/cities/?skip=0&limit=100`);
      if (!response.ok) {
        throw new Error('Failed to fetch cities');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  },

  async updateRealtorInfo(data) {
    try {
      const response = await fetch(`${BASE_URL}/realtors/me`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({
          phone: data.phone,
          name: data.name
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update realtor info');
      }

      return response.json();
    } catch (error) {
      console.error('Error updating realtor info:', error);
      throw error;
    }
  },

  async getRealtorById(id) {
    try {
      const response = await fetch(`${BASE_URL}/realtors/${id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch realtor data');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching realtor:', error);
      throw error;
    }
  },
}; 
import './styles.scss';
import { useState } from 'react';
import EditListingModal from '../EditListingModal';
import ListingStatsModal from '../ListingStatsModal';
import { apartmentService } from '../../../../services/apartmentService';

function ListingsGrid({ listings, isReadOnly }) {
  const [editingListing, setEditingListing] = useState(null);
  const [viewingStats, setViewingStats] = useState(null);

  const getFirstImage = (imagesString) => {
    if (!imagesString) return 'https://via.placeholder.com/300x200';
    return imagesString.split(',')[0];
  };

  return (
    <div className="listings-grid">
      {listings && listings.length > 0 ? (
        <>
          <div className="grid">
            {listings.map(listing => (
              <div key={listing.id} className="listing-card">
                <div className="listing-image">
                  <img src={getFirstImage(listing.images)} alt={listing.name} />
                </div>
                <div className="listing-content">
                  <h3 className="listing-title">{listing.name}</h3>
                  <div className="listing-price">{listing.price.toLocaleString()} ₽</div>
                  <div className="listing-location">
                    <i className="fas fa-map-marker-alt"></i>
                    {apartmentService.getCityName(listing.city_id)}, {listing.address}
                  </div>
                  <div className="listing-stats">
                    <div className="stat">
                      <i className="fas fa-eye"></i>
                      {listing.num_views || 0}
                    </div>
                    <div className="stat">
                      <i className="fas fa-heart"></i>
                      {listing.num_fevorites || 0}
                    </div>
                    <div className="stat">
                      <i className="fas fa-home"></i>
                      {listing.type}
                    </div>
                  </div>
                </div>
                {!isReadOnly && (
                  <div className="listing-actions">
                    <button 
                      className="action-button"
                      onClick={() => setEditingListing(listing)}
                    >
                      <i className="fas fa-edit"></i>
                      Редактировать
                    </button>
                    <button 
                      className="action-button"
                      onClick={() => setViewingStats(listing)}
                    >
                      <i className="fas fa-chart-line"></i>
                      Статистика
                    </button>
                    <button className="action-button delete">
                      <i className="fas fa-trash"></i>
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>

          {editingListing && (
            <EditListingModal
              listing={editingListing}
              onClose={() => setEditingListing(null)}
              onSave={(updatedData) => {
                console.log('Saving:', updatedData);
                setEditingListing(null);
              }}
            />
          )}

          {viewingStats && (
            <ListingStatsModal
              listing={viewingStats}
              onClose={() => setViewingStats(null)}
            />
          )}
        </>
      ) : (
        <div className="empty-state">
          <div className="empty-icon">
            <i className="fas fa-search"></i>
          </div>
          <h3>Ничего не нашлось</h3>
          <p>
            Возможно, в запрос закралась ошибка<br />
            или объявление переехало в другую вкладку.
          </p>
        </div>
      )}
    </div>
  );
}

export default ListingsGrid; 
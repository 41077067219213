const BASE_URL = 'https://api.overseas.optongroup.ru/api/auth';

export const authService = {
  async login(login, password) {
    try {
      console.log('AuthService login attempt:', { login, password });

      const formData = new URLSearchParams();
      formData.append('username', login);
      formData.append('password', password);

      const response = await fetch(`${BASE_URL}/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: formData.toString()
      });

      console.log('Response status:', response.status);

      const data = await response.json();
      console.log('Response data:', data);

      if (!response.ok) {
        throw new Error(data.detail || 'Неверный логин или пароль');
      }

      if (data.access_token) {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('user', JSON.stringify(data.user));
      }

      return data;
    } catch (error) {
      console.error('AuthService login error:', error);
      throw error;
    }
  },

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  },

  isAuthenticated() {
    return !!localStorage.getItem('token');
  },

  getUser() {
    const userStr = localStorage.getItem('user');
    return userStr ? JSON.parse(userStr) : null;
  },

  getUserRole() {
    const user = this.getUser();
    return user ? user.role : null;
  },

  async register(login, password, role = 'user') {
    try {
      console.log('AuthService register attempt:', { login, role });

      const response = await fetch(`${BASE_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          login,
          password,
          role
        })
      });

      const data = await response.json();
      console.log('Register response:', data);

      if (!response.ok) {
        throw new Error(data.detail || 'Ошибка при регистрации');
      }

      return data;
    } catch (error) {
      console.error('AuthService register error:', error);
      throw error;
    }
  }
};
import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService } from '../services/authService';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const savedUser = authService.getUser();
    if (savedUser) {
      setUser(savedUser);
    }
    setLoading(false);
  }, []);

  const login = async (loginValue, password) => {
    console.log('AuthContext login attempt:', { loginValue, password });
    const response = await authService.login(loginValue, password);
    console.log('AuthContext login response:', response);
    setUser(response.user);
    return response;
  };

  const register = async (loginValue, password) => {
    console.log('AuthContext register attempt:', { loginValue });
    const response = await authService.register(loginValue, password);
    console.log('AuthContext register response:', response);
    // После успешной регистрации выполняем вход
    return await login(loginValue, password);
  };

  const logout = () => {
    authService.logout();
    setUser(null);
  };

  const value = {
    user,
    login,
    register,
    logout,
    isAuthenticated: !!user
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { apartmentService } from '../../services/apartmentService';
import Pagination from '../../components/Pagination';
import PropertyStats from '../../components/PropertyStats';
import './styles.scss';

function SearchPage() {
  const { city = 'all', type = 'all' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    minPrice: searchParams.get('minPrice') || '',
    maxPrice: searchParams.get('maxPrice') || '',
    minArea: searchParams.get('minArea') || '',
    maxArea: searchParams.get('maxArea') || '',
    currencyId: searchParams.get('currency') || '',
    searchQuery: searchParams.get('q') || '',
    sortBy: searchParams.get('sortBy') || 'price',
    sortOrder: searchParams.get('sortOrder') || 'asc'
  });

  const [activeFilters, setActiveFilters] = useState(filters);

  const [apartments, setApartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')) || 1);
  const [totalPages, setTotalPages] = useState(1);

  const loadApartments = useCallback(async () => {
    try {
      setLoading(true);
      const data = await apartmentService.searchApartments({
        city,
        type,
        minPrice: activeFilters.minPrice || undefined,
        maxPrice: activeFilters.maxPrice || undefined,
        minArea: activeFilters.minArea || undefined,
        maxArea: activeFilters.maxArea || undefined,
        currencyId: activeFilters.currencyId || undefined,
        searchQuery: activeFilters.searchQuery || undefined,
        sortBy: activeFilters.sortBy,
        sortOrder: activeFilters.sortOrder,
        page: currentPage
      });
      
      setApartments(data.items);
      setTotalPages(Math.ceil(data.total / 20));
    } catch (err) {
      console.error('Error loading apartments:', err);
      setError(err.message || 'Failed to load apartments');
    } finally {
      setLoading(false);
    }
  }, [city, type, activeFilters, currentPage]);

  useEffect(() => {
    const params = new URLSearchParams();
    
    if (activeFilters.minPrice) params.set('minPrice', activeFilters.minPrice);
    if (activeFilters.maxPrice) params.set('maxPrice', activeFilters.maxPrice);
    if (activeFilters.minArea) params.set('minArea', activeFilters.minArea);
    if (activeFilters.maxArea) params.set('maxArea', activeFilters.maxArea);
    if (activeFilters.currencyId) params.set('currency', activeFilters.currencyId);
    if (activeFilters.searchQuery) params.set('q', activeFilters.searchQuery);
    if (activeFilters.sortBy !== 'price') params.set('sortBy', activeFilters.sortBy);
    if (activeFilters.sortOrder !== 'asc') params.set('sortOrder', activeFilters.sortOrder);
    if (currentPage > 1) params.set('page', currentPage);

    setSearchParams(params);
  }, [activeFilters, currentPage, setSearchParams]);

  useEffect(() => {
    loadApartments();
  }, [loadApartments]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleSortChange = (e) => {
    const [sortBy, sortOrder] = e.target.value.split('-');
    setFilters(prev => ({ ...prev, sortBy, sortOrder }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setActiveFilters(filters);
    loadApartments();
  };

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="search-page">
      <div className="container">
        <div className="search-header">
          <div className="breadcrumbs">
            <a href="/">Главная</a>
            <span>/</span>
            <span>Поиск</span>
          </div>
          <h1>
            {type !== 'all' ? type : 'Вся недвижимость'} в{' '}
            <span className="highlight">
              {city !== 'all' ? city : 'любом городе'}
            </span>
          </h1>
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="filters-section"
        >
          <form onSubmit={handleSearch} className="filters-section">
            <div className="filters-row">
              <input
                type="text"
                name="searchQuery"
                placeholder="Поиск по названию, описанию или адресу"
                value={filters.searchQuery}
                onChange={handleFilterChange}
              />
            </div>

            <div className="filters-row">
              <div className="price-range">
                <input
                  type="number"
                  name="minPrice"
                  placeholder="Цена от"
                  value={filters.minPrice}
                  onChange={handleFilterChange}
                />
                <input
                  type="number"
                  name="maxPrice"
                  placeholder="Цена до"
                  value={filters.maxPrice}
                  onChange={handleFilterChange}
                />
              </div>

              <div className="area-range">
                <input
                  type="number"
                  name="minArea"
                  placeholder="Площадь от"
                  value={filters.minArea}
                  onChange={handleFilterChange}
                />
                <input
                  type="number"
                  name="maxArea"
                  placeholder="Площадь до"
                  value={filters.maxArea}
                  onChange={handleFilterChange}
                />
              </div>
            </div>

            <div className="filters-row">
              <select 
                name="currencyId"
                value={filters.currencyId}
                onChange={handleFilterChange}
              >
                <option value="">Все валюты</option>
                <option value="1">USD ($)</option>
                <option value="2">EUR (€)</option>
                <option value="3">RUB (₽)</option>
              </select>

              <select
                name="sort"
                value={`${filters.sortBy}-${filters.sortOrder}`}
                onChange={handleSortChange}
              >
                <option value="price-asc">Цена (по возрастанию)</option>
                <option value="price-desc">Цена (по убыванию)</option>
                <option value="date-desc">Сначала новые</option>
                <option value="date-asc">Сначала старые</option>
              </select>

              <button type="submit" className="apply-filters">
                Применить фильтры
              </button>
            </div>
          </form>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="results-count"
        >
          Найдено {apartments.length} объектов
        </motion.div>

        <AnimatePresence mode="wait">
          {loading ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="loading"
            >
              Загрузка...
            </motion.div>
          ) : (
            <motion.div
              key="results"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="properties-grid"
            >
              {apartments.map((apartment, index) => (
                <motion.div
                  key={apartment.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ 
                    opacity: 1, 
                    y: 0,
                    transition: { delay: index * 0.1 }
                  }}
                  className="property-card"
                  onClick={() => navigate(`/object/${apartment.id}`)}
                >
                  <div className="property-image">
                    {apartment.images ? (
                      <img 
                        src={apartment.images.split(',')[0]} 
                        alt={apartment.name} 
                      />
                    ) : (
                      <div className="no-image">Нет фото</div>
                    )}
                    <div className="image-count">
                      <i className="fas fa-camera"></i>
                      {apartment.images ? apartment.images.split(',').length : 0}
                    </div>
                  </div>
                  <div className="property-content">
                    <h3>{apartment.name}</h3>
                    <p className="property-address">
                      <i className="fas fa-map-marker-alt"></i>
                      {apartment.address}
                    </p>
                    <div className="property-price">
                      {apartment.price.toLocaleString()} {apartment.currency?.symbol || '₽'}
                    </div>
                    <div className="property-realtor">
                      <div className="realtor-avatar">
                        {apartment.realtor?.name?.charAt(0) || '?'}
                      </div>
                      <div className="realtor-info">
                        <div className="realtor-name">{apartment.realtor?.name || 'Риелтор'}</div>
                        <div className="realtor-rating">
                          <i className="fas fa-star"></i>
                          {apartment.realtor?.rating || '4.5'}
                        </div>
                      </div>
                    </div>
                    <button className="contact-btn">Связаться</button>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>

        {totalPages > 1 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.3 }}
          >
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
            />
          </motion.div>
        )}

        <PropertyStats />
      </div>
    </div>
  );
}

export default SearchPage;
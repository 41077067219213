import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { apartmentService } from '../../services/apartmentService';
import './styles.scss';

function PropertyStats() {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCharts, setShowCharts] = useState(false);

  useEffect(() => {
    loadStats();
  }, []);

  useEffect(() => {
    if (stats && !loading) {
      const timer = setTimeout(() => {
        setShowCharts(true);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [stats, loading]);

  const loadStats = async () => {
    try {
      setLoading(true);
      const data = await apartmentService.getStats();
      setStats(data);
    } catch (err) {
      console.error('Error loading stats:', err);
      setError(err.message || 'Failed to load statistics');
    } finally {
      setLoading(false);
    }
  };

  const ChartBar = ({ label, count, total, index }) => (
    <motion.div 
      className="chart-bar"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      <div className="bar-label">{label}</div>
      <div className="bar-container">
        <motion.div 
          className="bar-value"
          initial={{ width: 0 }}
          animate={{ width: `${(count / total) * 100}%` }}
          transition={{ 
            duration: 1,
            delay: index * 0.1,
            ease: "easeOut"
          }}
        >
          <span className="count">{count}</span>
        </motion.div>
        <div className="bar-background" />
      </div>
    </motion.div>
  );

  if (loading) return (
    <motion.div 
      className="stats-loading"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="loading-spinner" />
      Загрузка статистики...
    </motion.div>
  );
  
  if (error) return null;
  if (!stats) return null;

  return (
    <motion.div 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="stats-section"
    >
      <motion.h2
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
      >
        Статистика объявлений
      </motion.h2>
      
      <div className="stats-grid">
        {Object.entries({
          total: { value: stats.total_count, label: 'Всего объявлений' },
          avg: { value: stats.price_range.avg, label: 'Средняя цена' },
          min: { value: stats.price_range.min, label: 'Минимальная цена' },
          max: { value: stats.price_range.max, label: 'Максимальная цена' }
        }).map(([key, { value, label }], index) => (
          <motion.div
            key={key}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            className="stat-card"
          >
            <motion.div 
              className="stat-value"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: index * 0.1 + 0.2 }}
            >
              {typeof value === 'number' ? value.toLocaleString() : value} ₽
            </motion.div>
            <div className="stat-label">{label}</div>
          </motion.div>
        ))}
      </div>

      <AnimatePresence>
        {showCharts && (
          <motion.div 
            className="stats-charts"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <motion.div 
              className="chart-container"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.3 }}
            >
              <h3>По типу недвижимости</h3>
              <div className="chart-content">
                {stats.by_type.map(([type, count], index) => (
                  <ChartBar
                    key={type}
                    label={type}
                    count={count}
                    total={stats.total_count}
                    index={index}
                  />
                ))}
              </div>
            </motion.div>

            <motion.div 
              className="chart-container"
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
            >
              <h3>По городам</h3>
              <div className="chart-content">
                {stats.by_city.map(([city, count], index) => (
                  <ChartBar
                    key={city}
                    label={city}
                    count={count}
                    total={stats.total_count}
                    index={index}
                  />
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

export default PropertyStats; 
import React, { useState, useEffect, useCallback } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { userService } from '../../services/userService';
import SEO from '../../components/SEO';
import UserProfile from './components/UserProfile';
import AddApartmentForm from './components/AddApartmentForm';
import './styles.scss';
import { apartmentService } from '../../services/apartmentService';
import { useNavigate } from 'react-router-dom';
import EditApartmentForm from './components/EditApartmentForm';

function Account() {
  const { user } = useAuth();
  const [userData, setUserData] = useState({
    favorites: [],
    requests: [],
    realtorInfo: null
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [realtorObjects, setRealtorObjects] = useState([]);
  const [editingObject, setEditingObject] = useState(null);
  const navigate = useNavigate();

  const loadUserData = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);

      // Загружаем основные данные пользователя
      const data = await userService.getUserData();
      
      // Если пользователь риелтор, загружаем дополнительную информацию
      let realtorInfo = null;
      if (data.role === 'realtor') {
        try {
          realtorInfo = await userService.getRealtorInfo();
          console.log('Loaded realtor info:', realtorInfo);
        } catch (error) {
          console.warn('Failed to load realtor info:', error);
        }
      }

      // Загружаем избранное и заявки
      const [favorites, requests] = await Promise.all([
        userService.getFavorites().catch(() => []),
        userService.getRequests().catch(() => [])
      ]);

      setUserData({
        ...data,
        favorites: favorites || [],
        requests: requests || [],
        realtorInfo: realtorInfo || null
      });
    } catch (err) {
      console.error('Error loading user data:', err);
      setError('Ошибка загрузки данных пользователя');
    } finally {
      setLoading(false);
    }
  }, []);

  const loadRealtorObjects = useCallback(async () => {
    if (userData?.realtorInfo?.id) {
      try {
        // Убе��имся, что города загружены перед загрузкой объектов
        await apartmentService.loadCities();
        const objects = await apartmentService.getRealtorObjects(userData.realtorInfo.id);
        setRealtorObjects(objects);
      } catch (err) {
        console.error('Error loading realtor objects:', err);
      }
    }
  }, [userData?.realtorInfo?.id]);

  useEffect(() => {
    if (user) {
      loadUserData();
    }
  }, [user, loadUserData]);

  useEffect(() => {
    loadRealtorObjects();
  }, [loadRealtorObjects]);

  // Загрузка городов при монтировании компонента
  useEffect(() => {
    apartmentService.loadCities();
  }, []);

  const handleEditObject = (object) => {
    setEditingObject(object);
  };

  if (!user) {
    return <div className="account-page">Пожалуйста, войдите в аккаунт</div>;
  }

  if (loading) {
    return <div className="account-page">Загрузка...</div>;
  }

  if (error) {
    return <div className="account-page">Ошибка: {error}</div>;
  }

  return (
    <div className="account-page">
      <SEO 
        title={userData.realtorInfo ? "Кабинет риелтора" : "Личный кабинет"}
        description="Управление объявлениями и настройками профиля"
      />
      
      <div className="account-container">
        <UserProfile 
          name={userData.name}
          role={userData.role}
          favorites={userData.favorites}
          requests={userData.requests}
          realtorInfo={userData.realtorInfo}
          onAddListing={() => setShowAddForm(true)}
        />

        {showAddForm && (
          <AddApartmentForm 
            onSuccess={() => {
              loadUserData();
              loadRealtorObjects();
              setShowAddForm(false);
            }}
            onClose={() => setShowAddForm(false)}
          />
        )}

        {userData.role === 'realtor' && (
          <>
            <div className="realtor-objects">
              <h2>Мои объявления</h2>
              <div className="objects-grid">
                {realtorObjects.map(object => (
                  <div key={object.id} className="object-card">
                    <div className="object-image">
                      {object.images ? (
                        <img 
                          src={object.images.split(',')[0]} 
                          alt={object.name} 
                        />
                      ) : (
                        <div className="no-image">Нет фото</div>
                      )}
                    </div>
                    <div className="object-content">
                      <h3>{object.name}</h3>
                      <p className="object-address">
                        {apartmentService.getCityName(object.city_id)}, {object.address}
                      </p>
                      <p className="object-price">{object.price} ₽</p>
                      <div className="object-stats">
                        <span>
                          <i className="fas fa-eye"></i> {object.num_views}
                        </span>
                        <span>
                          <i className="fas fa-heart"></i> {object.num_fevorites}
                        </span>
                      </div>
                      <div className="object-actions">
                        <button 
                          className="view-button"
                          onClick={() => navigate(`/object/${object.id}`)}
                        >
                          <i className="fas fa-eye"></i>
                          Просмотр
                        </button>
                        <button 
                          className="edit-button"
                          onClick={() => handleEditObject(object)}
                        >
                          <i className="fas fa-cog"></i>
                          Настройка
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {userData.role === 'user' && (
          <div className="user-content">
            <div className="favorites-section">
              <h2>Избранные объекты</h2>
              <div className="favorites-grid">
                {userData.favorites.map(favorite => (
                  <div key={favorite.id} className="favorite-item">
                    <h3>{favorite.name}</h3>
                    <p>{favorite.address}</p>
                    <p>Цена: {favorite.price} ₽</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="requests-section">
              <h2>Мои заявки</h2>
              <div className="requests-grid">
                {userData.requests.map(request => (
                  <div key={request.id} className="request-item">
                    <p>Объект: {request.apartment_name}</p>
                    <p>Телефон: {request.phone}</p>
                    <p>Статус: {request.status}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {editingObject && (
          <EditApartmentForm
            apartment={editingObject}
            onClose={() => setEditingObject(null)}
            onSuccess={() => {
              loadRealtorObjects();
              setEditingObject(null);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default Account; 
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import SEO from '../../components/SEO';
import ListingsGrid from '../Account/components/ListingsGrid';
import './styles.scss';
import { apartmentService } from '../../services/apartmentService';

const BASE_URL = process.env.REACT_APP_API_URL;

function RealtorProfile() {
  const { realtor_id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    apartmentService.loadCities();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`/api/realtors/${realtor_id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json'
          }
        });
        
        if (response.status === 404) {
          setError('Риелтор не найден');
          return;
        }
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const realtorData = await response.json();
        setData(realtorData);
      } catch (err) {
        console.error('Error:', err);
        setError('Произошла ошибка при загрузке данных');
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, [realtor_id]);

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return (
    <div className="not-found-page">
      <div className="not-found-content">
        <i className="fas fa-user-slash"></i>
        <h1>{error}</h1>
        <p>Проверьте правильность введенного ID риелтора</p>
      </div>
    </div>
  );
  if (!data) return null;

  return (
    <div className="account-page">
      <SEO 
        title={`Риелтор ${data.name}`}
        description={`Профиль риелтора ${data.name} и его объявления`}
      />
      
      <div className="account-container">
        <div className="user-profile">
          <div className="profile-header">
            <div className="user-info">
              <div className="user-details">
                <div className="user-name-wrapper">
                  <h1 className="user-name">{data.name}</h1>
                  <span className="user-role">Риелтор</span>
                </div>
                <div className="realtor-details">
                  <div className="realtor-phone">
                    <i className="fas fa-phone"></i>
                    {data.phone}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="listings-section">
          <ListingsGrid 
            listings={data.objects}
            isReadOnly={true}
          />
        </div>
      </div>
    </div>
  );
}

export default RealtorProfile; 
import { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apartmentService } from '../../services/apartmentService';
import SEO from '../../components/SEO';
import './styles.scss';

function ObjectPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const thumbnailsRef = useRef(null);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    const loadProperty = async () => {
      try {
        if (!isFirstLoad.current) {
          return;
        }
        isFirstLoad.current = false;

        setLoading(true);
        const data = await apartmentService.getApartmentById(id);
        
        // Преобразуем данные в нужный формат
        const formattedData = {
          id: data.id,
          title: data.name,
          address: data.address,
          price: data.price,
          agent: {
            name: data.realtor?.name || 'Риелтор не указан',
            rating: data.realtor?.rating || 0,
            reviews: data.realtor?.reviews_count || 0
          },
          features: {
            bedrooms: data.bedrooms || 0,
            bathrooms: data.bathrooms || 0,
            parking: data.parking || 'Нет',
            area: `${data.area || 0} м²`
          },
          description: data.description,
          images: data.images ? data.images.split(',').map(img => img.trim()) : []
        };

        setProperty(formattedData);
      } catch (err) {
        console.error('Error loading property:', err);
        setError(err.message || 'Failed to load property details');
      } finally {
        setLoading(false);
      }
    };

    loadProperty();

    return () => {
      isFirstLoad.current = true;
    };
  }, [id]);

  const handlePrevImage = () => {
    setActiveImageIndex((prev) => 
      prev === 0 ? property.images.length - 1 : prev - 1
    );
  };

  const handleNextImage = () => {
    setActiveImageIndex((prev) => 
      prev === property.images.length - 1 ? 0 : prev + 1
    );
  };

  const handleThumbnailClick = (index) => {
    setActiveImageIndex(index);
  };

  // Добавим эффект для прокрутки к активной миниатюре
  useEffect(() => {
    if (thumbnailsRef.current) {
      const container = thumbnailsRef.current;
      const activeThumb = container.children[activeImageIndex];
      
      if (activeThumb) {
        const containerWidth = container.offsetWidth;
        const thumbLeft = activeThumb.offsetLeft;
        const thumbWidth = activeThumb.offsetWidth;
        
        // Вычисляем позицию для центрирования активной миниатюры
        const scrollPosition = thumbLeft - (containerWidth / 2) + (thumbWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [activeImageIndex]);

  if (loading) return <div className="loading">Загрузка...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!property) return <div className="error">Объект не найден</div>;

  return (
    <div className="object-page">
      <SEO 
        title={property.title}
        description={property.description}
      />

      {property.images && property.images.length > 0 ? (
        <div className={`gallery-section ${isFullscreen ? 'fullscreen' : ''}`}>
          <div className="gallery-container">
            <div className="main-image">
              <img src={property.images[activeImageIndex]} alt={property.title} />
              
              <button className="gallery-nav prev" onClick={handlePrevImage}>
                <i className="fas fa-chevron-left"></i>
              </button>
              
              <button className="gallery-nav next" onClick={handleNextImage}>
                <i className="fas fa-chevron-right"></i>
              </button>

              <button 
                className="fullscreen-toggle"
                onClick={() => setIsFullscreen(!isFullscreen)}
              >
                <i className={`fas fa-${isFullscreen ? 'compress' : 'expand'}`}></i>
              </button>

              <div className="image-counter">
                {activeImageIndex + 1} / {property.images.length}
              </div>
            </div>

            <div className="thumbnails-container" ref={thumbnailsRef}>
              {property.images.map((image, index) => (
                <div 
                  key={index}
                  className={`thumbnail ${index === activeImageIndex ? 'active' : ''}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img src={image} alt={`${property.title} - фото ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="no-images">
          <i className="fas fa-image"></i>
          <p>Нет фотографий</p>
        </div>
      )}

      <div className="content-section">
        <div className="content-container">
          <div className="main-content">
            <div className="object-header">
              <h1>{property.title}</h1>
              <div className="object-address">
                <i className="fas fa-map-marker-alt"></i> {property.address}
              </div>
            </div>

            <div className="agent-info">
              <div className="agent-avatar">
                <div className="avatar-placeholder"></div>
              </div>
              <div className="agent-details">
                <div className="agent-name">{property.agent.name}</div>
                <div className="agent-stats">
                  <div className="agent-rating">
                    <i className="fas fa-star"></i>
                    <span>{property.agent.rating}</span>
                  </div>
                  <span className="reviews-count">{property.agent.reviews} отзывов</span>
                </div>
              </div>
            </div>

            <div className="features-grid">
              <div className="feature">
                <i className="fas fa-bed"></i>
                <div className="feature-label">Спальни</div>
                <div className="feature-value">{property.features.bedrooms} спальни</div>
              </div>
              <div className="feature">
                <i className="fas fa-bath"></i>
                <div className="feature-label">Санузлы</div>
                <div className="feature-value">{property.features.bathrooms} санузел</div>
              </div>
              <div className="feature">
                <i className="fas fa-parking"></i>
                <div className="feature-label">Парковка</div>
                <div className="feature-value">{property.features.parking}</div>
              </div>
              <div className="feature">
                <i className="fas fa-vector-square"></i>
                <div className="feature-label">Площадь</div>
                <div className="feature-value">{property.features.area}</div>
              </div>
            </div>

            <div className="description">
              <h2>Description:</h2>
              <p>{property.description}</p>
            </div>
          </div>

          <div className="sidebar">
            <div className="price-card">
              <div className="price">
                {property.price.toLocaleString()} руб.
              </div>
              <button className="contact-btn">
                Связаться с риелтором
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObjectPage; 
import { useState } from 'react';
import { apartmentService } from '../../../../services/apartmentService';
import './styles.scss';

const currencies = [
  { id: 1, name: "USD", symbol: "$" },
  { id: 2, name: "EUR", symbol: "€" },
  { id: 3, name: "RUB", symbol: "₽" }
];

const cities = [
  { id: 1, name: "Москва" },
  { id: 2, name: "Санкт-Петербург" },
  { id: 3, name: "Новосибирск" },
  { id: 4, name: "Екатеринбург" },
  { id: 5, name: "Казань" }
];

function CreateApartmentModal({ onClose, onSuccess }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: '',
    city_id: '',
    address: '',
    coordinates: '',
    price: '',
    currency_id: '',
    images: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');

      const data = {
        ...formData,
        city_id: parseInt(formData.city_id),
        price: parseInt(formData.price),
        currency_id: parseInt(formData.currency_id)
      };

      const result = await apartmentService.createApartment(data);
      onSuccess(result);
      onClose();
    } catch (err) {
      console.error('Error creating apartment:', err);
      if (err.message === 'Unauthorized: Please log in again') {
        setError('Session expired. Please log in again.');
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>Новое объявление</h2>
          <button className="close-button" onClick={onClose}>
            <i className="fas fa-times"></i>
          </button>
        </div>

        {error && (
          <div className="error-message">
            <i className="fas fa-exclamation-circle"></i>
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Название</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Описание</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Тип недвижимости</label>
            <select
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Выберите тип</option>
              <option value="apartment">Квартира</option>
              <option value="house">Дом</option>
              <option value="commercial">Коммерческая недвижимость</option>
            </select>
          </div>

          <div className="form-row">
            <div className="form-group">
              <label>Город</label>
              <select
                name="city_id"
                value={formData.city_id}
                onChange={handleChange}
                required
              >
                <option value="">Выберите город</option>
                {cities.map(city => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label>Адрес</label>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                required
              />
            </div>
          </div>

          <div className="form-group">
            <label>Координаты</label>
            <input
              type="text"
              name="coordinates"
              value={formData.coordinates}
              onChange={handleChange}
              placeholder="55.755819, 37.617644"
              required
            />
          </div>

          <div className="form-group">
            <label>Цена:</label>
            <div className="price-input">
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                required
              />
              <select
                name="currency_id"
                value={formData.currency_id}
                onChange={handleChange}
              >
                {currencies.map(currency => (
                  <option key={currency.id} value={currency.id}>
                    {currency.symbol}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label>Изображения</label>
            <input
              type="text"
              name="images"
              value={formData.images}
              onChange={handleChange}
              placeholder="URL изображений через запятую"
              required
            />
          </div>

          <div className="form-actions">
            <button type="button" className="cancel-button" onClick={onClose}>
              Отменить
            </button>
            <button type="submit" className="save-button" disabled={loading}>
              {loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i>
                  Создание...
                </>
              ) : (
                'Создать объявление'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateApartmentModal;
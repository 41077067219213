const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.overseas.optongroup.ru/api';

// Добавим объект для маппинга ID городов к их названиям
const CITIES = {
  1: 'Москва',
  2: 'Санкт-Петербург',
  3: 'Новосибирск',
  4: 'Екатеринбург',
  5: 'Казань'
};

// Кэш для объявлений с временем жизни
class ApartmentCache {
  constructor(ttl = 5 * 60 * 1000) { // TTL по умолчанию 5 минут
    this.cache = new Map();
    this.ttl = ttl;
  }

  set(id, data) {
    this.cache.set(id, {
      data,
      timestamp: Date.now()
    });
  }

  get(id) {
    const item = this.cache.get(id);
    if (!item) return null;

    // Проверяем не истекло ли время жизни кэша
    if (Date.now() - item.timestamp > this.ttl) {
      this.cache.delete(id);
      return null;
    }

    return item.data;
  }

  clear() {
    this.cache.clear();
  }
}

const apartmentCache = new ApartmentCache();

export const apartmentService = {
  async searchApartments({ 
    city, 
    type, 
    minPrice, 
    maxPrice, 
    minArea, 
    maxArea,
    searchQuery,
    sortBy = 'price',
    sortOrder = 'asc',
    page = 1, 
    limit = 20 
  }) {
    try {
      let url = `${BASE_URL}/apartments/search?`;
      const params = new URLSearchParams();
      
      if (city && city !== 'all') params.append('city', city);
      if (type && type !== 'all') params.append('type', type);
      if (minPrice) params.append('min_price', minPrice);
      if (maxPrice) params.append('max_price', maxPrice);
      if (minArea) params.append('min_area', minArea);
      if (maxArea) params.append('max_area', maxArea);
      if (searchQuery) params.append('search_query', searchQuery);
      if (sortBy) params.append('sort_by', sortBy);
      if (sortOrder) params.append('sort_order', sortOrder);
      
      params.append('skip', (page - 1) * limit);
      params.append('limit', limit);

      const response = await fetch(`${url}${params.toString()}`);

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error('Failed to fetch apartments');
      }

      return response.json();
    } catch (error) {
      console.error('Error searching apartments:', error);
      throw error;
    }
  },

  async getApartmentById(id, signal) {
    try {
      // Проверяем кэш
      const cachedData = apartmentCache.get(id);
      if (cachedData) {
        console.log('Returning cached data for apartment:', id);
        return cachedData;
      }

      const response = await fetch(`${BASE_URL}/apartments/${id}`, {
        signal,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error('Failed to fetch apartment details');
      }

      const data = await response.json();
      
      // Сохраняем в кэш только если запрос не был отменен
      if (!signal?.aborted) {
        console.log('Caching data for apartment:', id);
        apartmentCache.set(id, data);
      }

      return data;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
        return null;
      }
      console.error('Error fetching apartment details:', error);
      throw error;
    }
  },

  async getRealtorListings() {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No token found');
    }

    try {
      const response = await fetch(`${BASE_URL}/realtors/me/listings`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        if (response.status === 401) {
          throw new Error('Unauthorized: Please log in again');
        }
        throw new Error('Failed to fetch realtor listings');
      }

      return response.json();
    } catch (error) {
      console.error('Error fetching realtor listings:', error);
      throw error;
    }
  },

  async createApartment(data) {
    try {
      const apartmentData = {
        name: data.name,
        description: data.description || '',
        type: data.type,
        city_id: parseInt(data.city_id),
        address: data.address,
        coordinates: data.coordinates || null,
        price: parseFloat(data.price),
        images: data.images || null,
        realtor_id: parseInt(data.realtor_id)
      };

      const response = await fetch(`${BASE_URL}/apartments/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(apartmentData)
      });

      if (!response.ok) {
        throw new Error('Failed to create apartment');
      }

      return response.json();
    } catch (error) {
      console.error('Error creating apartment:', error);
      throw error;
    }
  },

  async updateApartment(id, data) {
    try {
      const response = await fetch(`${BASE_URL}/apartments/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Failed to update apartment');
      }

      // Очищаем кэш для этого объявления
      apartmentCache.cache.delete(id);
      
      return response.json();
    } catch (error) {
      console.error('Error updating apartment:', error);
      throw error;
    }
  },

  async deleteApartment(id) {
    try {
      const response = await fetch(`${BASE_URL}/apartments/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete apartment');
      }

      // Очищаем кэш для этого объявления
      apartmentCache.cache.delete(id);

      return true;
    } catch (error) {
      console.error('Error deleting apartment:', error);
      throw error;
    }
  },

  async toggleFavorite(id) {
    try {
      const response = await fetch(`${BASE_URL}/apartments/${id}/favorite`, {
        method: 'POST'
      });

      if (!response.ok) {
        throw new Error('Failed to toggle favorite');
      }

      return response.json();
    } catch (error) {
      console.error('Error toggling favorite:', error);
      throw error;
    }
  },

  async getApartmentTypes() {
    try {
      const response = await fetch(`${BASE_URL}/apartments/types`);
      if (!response.ok) {
        throw new Error('Failed to fetch apartment types');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching apartment types:', error);
      throw error;
    }
  },

  async getApartmentType(typeId) {
    try {
      const response = await fetch(`${BASE_URL}/apartments/types/${typeId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch apartment type');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching apartment type:', error);
      throw error;
    }
  },

  async getRealtorObjects(realtorId) {
    try {
      const response = await fetch(`${BASE_URL}/realtors/${realtorId}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch realtor objects');
      }

      const data = await response.json();
      return data.objects || [];
    } catch (error) {
      console.error('Error fetching realtor objects:', error);
      throw error;
    }
  },

  getCityName(cityId) {
    return CITIES[cityId] || 'Неизвестный город';
  },

  async getCities() {
    try {
      const response = await fetch(`${BASE_URL}/locations/cities`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        throw new Error('Failed to fetch cities');
      }
      return response.json();
    } catch (error) {
      console.error('Error fetching cities:', error);
      return [];
    }
  },

  cities: new Map(), // Кэш для городов

  async loadCities() {
    try {
      const response = await fetch(`${BASE_URL}/locations/cities`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch cities');
      }
      const cities = await response.json();
      cities.forEach(city => {
        this.cities.set(city.id, city.name);
      });
    } catch (error) {
      console.error('Error loading cities:', error);
    }
  },

  getCityName(cityId) {
    return this.cities.get(cityId) || 'Неизвестный город';
  },
};
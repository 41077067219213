import { useState, useEffect } from 'react';
import { apartmentService } from '../../../../services/apartmentService';
import './styles.scss';

function EditApartmentForm({ apartment, onClose, onSuccess }) {
  const [formData, setFormData] = useState({
    name: apartment.name,
    description: apartment.description || '',
    type: apartment.type,
    city_id: apartment.city_id,
    address: apartment.address,
    coordinates: apartment.coordinates || '',
    price: apartment.price,
    images: apartment.images || '',
  });

  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const [typesData, citiesData] = await Promise.all([
          apartmentService.getApartmentTypes(),
          apartmentService.getCities()
        ]);
        
        if (typesData.length === 0) {
          setError('Не удалось загрузить типы недвижимости');
          return;
        }
        
        if (citiesData.length === 0) {
          setError('Не удалось загрузить список городов');
          return;
        }
        
        setApartmentTypes(typesData);
        setCities(citiesData);
      } catch (error) {
        console.error('Error loading form data:', error);
        setError('Ошибка загрузки данных формы');
      }
    };

    loadData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User info not found');
      }

      const dataToSend = {
        ...formData,
        realtor_id: user.id
      };

      await apartmentService.updateApartment(apartment.id, dataToSend);
      onSuccess();
      onClose();
    } catch (err) {
      console.error('Update error:', err);
      setError(err.message || 'Ошибка при обновлении объявления');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Вы уверены, что хотите удалить это объявление?')) {
      setLoading(true);
      try {
        await apartmentService.deleteApartment(apartment.id);
        onSuccess();
        onClose();
      } catch (err) {
        setError(err.message || 'Ошибка при удалении объявления');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="edit-apartment-form-overlay" onClick={onClose}>
      <div className="edit-apartment-form" onClick={e => e.stopPropagation()}>
        <h3>Редактировать объявление</h3>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Название</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Оп��сание</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Тип недвижимости</label>
            <select 
              name="type" 
              value={formData.type}
              onChange={handleChange}
              required
            >
              {apartmentTypes.map(type => (
                <option key={type.id} value={type.short_name}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Город</label>
            <select 
              name="city_id"
              value={formData.city_id}
              onChange={handleChange}
              required
            >
              {cities.map(city => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Адрес</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Координаты</label>
            <input
              type="text"
              name="coordinates"
              value={formData.coordinates}
              onChange={handleChange}
              placeholder="Например: 55.7558, 37.6173"
            />
          </div>

          <div className="form-group">
            <label>Цена</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Изображения (URL через запятую)</label>
            <input
              type="text"
              name="images"
              value={formData.images}
              onChange={handleChange}
              placeholder="URL1, URL2, URL3"
            />
          </div>

          <div className="form-actions">
            <button type="button" className="delete-button" onClick={handleDelete}>
              Удалить объявление
            </button>
            <button type="submit" className="save-button" disabled={loading}>
              {loading ? 'Сохранение...' : 'Сохранить изменения'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditApartmentForm; 
import { useState } from 'react';
import './styles.scss';
import RealtorSettings from '../RealtorSettings';
import CreateApartmentModal from '../CreateApartmentModal';

function UserProfile({ name, role, favorites = 0, requests = 0, realtorInfo = null, onAddListing }) {
  const [showSettings, setShowSettings] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createError, setCreateError] = useState('');

  const getRoleDisplay = (role) => {
    switch(role) {
      case 'admin':
        return 'Администратор';
      case 'realtor':
        return 'Риелтор';
      case 'user':
        return 'Пользователь';
      default:
        return role;
    }
  };

  const handleCreateSuccess = (newApartment) => {
    console.log('New apartment created:', newApartment);
    setCreateError('');
  };

  return (
    <div className="user-profile">
      <div className="profile-header">
        <div className="user-info">
          <div className="user-details">
            <div className="user-name-wrapper">
              <h1 className="user-name">{realtorInfo?.name || name}</h1>
            </div>
            {realtorInfo && (
              <div className="realtor-details">
                <div className="realtor-phone">
                  <i className="fas fa-phone"></i>
                  {realtorInfo.phone}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="profile-actions">
          {role === 'realtor' && (
            <>
              <button 
                className="add-listing-button"
                onClick={onAddListing}
              >
                <i className="fas fa-plus"></i>
                Добавить объявление
              </button>
              <button 
                className="action-button secondary"
                onClick={() => setShowSettings(true)}
              >
                <i className="fas fa-cog"></i>
                Настройки
              </button>
            </>
          )}
        </div>
      </div>

      {showSettings && realtorInfo && (
        <RealtorSettings
          realtorInfo={realtorInfo}
          onClose={() => setShowSettings(false)}
          onUpdate={(updatedInfo) => {
            console.log('Realtor info updated:', updatedInfo);
          }}
        />
      )}

      {showCreateModal && (
        <CreateApartmentModal
          onClose={() => {
            setShowCreateModal(false);
            setCreateError('');
          }}
          onSuccess={handleCreateSuccess}
        />
      )}

      {createError && (
        <div className="error-message">
          <i className="fas fa-exclamation-circle"></i>
          {createError}
        </div>
      )}
    </div>
  );
}

export default UserProfile;
import React, { useState, useEffect } from 'react';
import { userService } from '../../../../services/userService';
import './styles.scss';

function AddApartmentForm({ onSuccess, onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    type: '',
    city_id: '',
    address: '',
    coordinates: '',
    price: '',
    images: ''
  });

  const [apartmentTypes, setApartmentTypes] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Загрузка типов недвижимости и городов при монтировании компонента
  useEffect(() => {
    const loadData = async () => {
      try {
        const [typesData, citiesData] = await Promise.all([
          userService.getApartmentTypes(),
          userService.getCities()
        ]);
        
        setApartmentTypes(typesData);
        setCities(citiesData);
        
        // Устанавливаем первый тип как значение по умолчанию
        if (typesData.length > 0) {
          setFormData(prev => ({
            ...prev,
            type: typesData[0].short_name
          }));
        }
      } catch (error) {
        console.error('Error loading form data:', error);
        setError('Ошибка загрузки данных формы');
      }
    };

    loadData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      // Получаем данные пользователя
      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User info not found');
      }

      const response = await userService.createApartment({
        ...formData,
        realtor_id: user.id  // Добавляем ID риелтора
      });

      console.log('Apartment created:', response);
      setFormData({
        name: '',
        description: '',
        type: apartmentTypes[0]?.short_name || '',
        city_id: '',
        address: '',
        coordinates: '',
        price: '',
        images: ''
      });
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (err) {
      console.error('Error creating apartment:', err);
      setError(err.message || 'Ошибка при создании объявления');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-apartment-form-overlay" onClick={onClose}>
      <div className="add-apartment-form" onClick={e => e.stopPropagation()}>
        <h3>Добавить объявление</h3>
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Название</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Описание</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>Тип недвижимости</label>
            <select 
              name="type" 
              value={formData.type} 
              onChange={handleChange}
              required
            >
              <option value="">Выберите тип</option>
              {apartmentTypes.map(type => (
                <option key={type.id} value={type.short_name}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Город</label>
            <select 
              name="city_id" 
              value={formData.city_id} 
              onChange={handleChange}
              required
            >
              <option value="">Выберите город</option>
              {cities.map(city => (
                <option key={city.id} value={city.id}>
                  {city.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group">
            <label>Адрес</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Координаты</label>
            <input
              type="text"
              name="coordinates"
              value={formData.coordinates}
              onChange={handleChange}
              placeholder="Например: 55.7558, 37.6173"
            />
          </div>

          <div className="form-group">
            <label>Цена</label>
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </div>

          <div className="form-group">
            <label>Изображения (URL через запятую)</label>
            <input
              type="text"
              name="images"
              value={formData.images}
              onChange={handleChange}
              placeholder="URL1, URL2, URL3"
            />
          </div>

          <button type="submit" disabled={loading}>
            {loading ? 'Добавление...' : 'Добавить объект'}
          </button>
        </form>
      </div>
    </div>
  );
}

export default AddApartmentForm; 